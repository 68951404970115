import React from "react"
import PropTypes from "prop-types"
import { graphql, Link } from "gatsby"
import styled from "styled-components"
import DefaultLayout from "../layouts/DefaultLayout"
import device from "../design/device"
import palette from "../design/palette"
import { Wrapper } from "../components/Layout"

const Container = styled.div`
  padding: 20px;
  height: 100vh;
  text-align: center;
  display: flex;
  align-items: center;
  h1 {
    line-height: 1.25;
    margin-top: 50px;
    margin-bottom: 50px;
  }
  ${device.phone`
    padding-top: 40px;
  `}
`

const Svg = styled.svg`
  max-width: 100px;
  path {
    fill: ${palette.lightAccent};
  }
`

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  & > :last-child {
    margin-left: 20px;
  }
`

const ThanksPageTemplate = ({ title, text }) => (
  <Container id="thanks-page">
    <Wrapper>
      <Svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 426.667 426.667"
      >
        <path
          d="M213.333,0C95.518,0,0,95.514,0,213.333s95.518,213.333,213.333,213.333 c117.828,0,213.333-95.514,213.333-213.333S331.157,0,213.333,0z M174.199,322.918l-93.935-93.931l31.309-31.309l62.626,62.622 l140.894-140.898l31.309,31.309L174.199,322.918z"
        />
      </Svg>

      <h1 className="h1">{title}</h1>
      <p className="p">{text}</p>

      <Buttons>
        <Link to="/">
          <span>Gå tilbake</span>
        </Link>
        <a href="https://bonum.no/prosjekter">
          Utforsk mer
        </a>
      </Buttons>
    </Wrapper>
  </Container>
)

ThanksPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
}

const ThanksPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <DefaultLayout>
      <ThanksPageTemplate
        title={frontmatter.title}
        text={frontmatter.text}
      />
    </DefaultLayout>
  )
}

ThanksPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object
    })
  })
}

export default ThanksPage

export const thanksPageQuery = graphql`
  query ThanksPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        text
      }
    }
  }
`
